html * {
  box-sizing: border-box;
}
h1 {
  font-weight: 800;
  font-size: 50px;
  color: darkslateblue;
}
h2 {
  font-weight: 700;
  font-size: 24px;
}
ul {
  list-style-type: none;
  font-size: 20px;
}
.post-content ul {
  list-style-type: disc;
}

.site-header {
  position: relative;
  padding: 38px 0 20px;
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
}
.casinos_box {
  display: grid;
  grid-template-columns: 1fr 220px;
  aside {
    min-width: 200px;
    padding: 10px 10px 0 30px;
    h3 {
      font-weight: 700;
      font-size: 20px;
      margin: 10px 0 0;
    }
    ul {
      margin: 0;
      position: sticky;
      list-style-type: none;
      top: 50px;
      li {
        padding: 5px 0;
        line-height: 1;
        a {
          padding: 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
          color: #37158d;
        }
      }
    }
  }
  .casinos_grid {
    display: grid;
    gap: 30px;
  }
}
.casino {
  display: grid;
  * {
    margin-bottom: 5px;
    color: $text-color;
  }
  & > * {
    padding: 2px 10px;
  }
  .casino_title {
    grid-column: 1/8;
    grid-row: 1/2;
    z-index: 5;
    a {
      font-size: 30px;
      color: rgb(17, 24, 215);
    }
  }
  .join {
    display: block;
    height: max-content;
    width: max-content;
    background: darkorange;
    font-size: 18px;
    line-height: 1.8;
    font-weight: 400;
    text-shadow: none;
    color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px 20px;

    &:hover {
      background: #b42121;
      text-decoration: none;
      color: #b5b1eb;
    }
  }
}
.last_col {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.casinos_header {
  background: #000;
  position: sticky;
  top: 160px;
  z-index: 5;
  h5 {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    a {
      &:hover {
        color: yellow;
        text-decoration: none;
      }
    }
  }
}
.casino_item {
  position: relative;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background: #fff;
  border: 1px solid #b5b1eb;
  border-radius: 10px;
  box-shadow: cadetblue 5px 4px 10px;
  * {
    z-index: 1;
    font-size: 14px;
    line-height: 1.1;
  }
  h3,
  h3 a {
    font-size: 18px;
    letter-spacing: 0;
    color: rgb(37, 44, 235);
  }
  .icon {
    margin: 10px;
    position: relative;
    display: block;
    width: 150px;
    height: 150px;
    background: rgb(23, 7, 65);
    border-radius: 10%;
    -webkit-border-radius: 10%;
    -moz-border-radius: 10%;
    -ms-border-radius: 10%;
    -o-border-radius: 10%;

    img {
      width: 80%;
      height: auto;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      -moz-transform: translateY(-50%) translateX(-50%);
      -ms-transform: translateY(-50%) translateX(-50%);
      -o-transform: translateY(-50%) translateX(-50%);
    }
  }
}
.footer-heading {
  color: #777;
}
@media (max-width: 1200px) {
  .site-title {
    font-size: 30px;
  }
  .casino_item {
    grid-template-columns: 170px repeat(3, 1fr);
    .casino_title {
      grid-column: 1/5;
    }
    .icon {
      grid-row: 2/4;
    }
  }
}
.reviews {
  display: grid;
  margin: 20px auto;
  h2 {
    grid-column: 1/3;
  }
  .casino_list {
    a {
      padding: 10px 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .site-logo img {
    width: 100px;
    padding: 5px 10px;
  }
  .casinos_box aside {
    max-width: 165px;
    min-width: 150px;
    ul li a {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 767px) {
  .casinos_box {
    display: block;
  }
  .casino_item {
    .icon {
      height: 140px;
      width: 140px;
    }
    grid-template-columns: repeat(2, 1fr);
    .casino_title {
      grid-column: 1/3;
    }
    .icon {
      height: 140px;
      width: 140px;
      grid-column: 1/2;
      grid-row: 2/3;
    }
    .last_col {
      grid-column: 1/3;
      grid-row: 5/6;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-end;
    }
  }
  .sidenav * {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .casinos_grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .casino .join {
    text-align: center;
    position: relative;
    bottom: 20px;
    width: 60%;
    right: 0;
  }
  span,
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
    padding: 5px 10px;
  }
  a {
    display: block;
    margin: 10px auto;
  }
  .footer-col * {
    padding: 0 10px;
  }
}
@media screen and (max-width: 414px) {
  .casinos_grid {
    gap: 5px;
  }
  .casino_item {
    display: block;
    .icon {
      display: block;
      margin: 10px auto;
    }
    .last_col {
      flex-direction: column;
      align-items: center;
    }
  }
  .site-title {
    font-size: 20px;
    line-height: 25px;
  }
}
.pagination ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3px;
  li {
    padding: 5px 16px;
    background: darkorange;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    &.active {
        background: red;
    }
  * {
      color: #fff;
      line-height: 1;
    }
  }
}
