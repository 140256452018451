/**
 * Site header
 */
.site-header {
  min-height: 80px;
  //border-radius: 0 0 50% 200%;
  //Positioningcontextforthemobilenavigationiconposition: sticky;
  top: 0;
  z-index: 10;
  background: #003760;
}
.header_after {
  width: 100%;
  margin-top: -2px;
}
.site-title {
  font-weight: 700;
  font-size: 56px;
  line-height: 1.1;
  letter-spacing: -1px;
  margin-bottom: 0;

  &,
  &:visited {
    color: #eee;
  }
}

/**
 * Site footer
 */
.site-footer {
  padding: $spacing-unit 0;
  background: rgb(32, 24, 24);
}

.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width: calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width: calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width: calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width: calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

/**
 * Page content
 */
.page-content {
  padding: 110px 0;
  margin-top: -90px;
  background-image: linear-gradient(rgba(244, 244, 255, 0.95), rgba(255, 255, 244, 0.95)),
    url("/images/bg/roulette-1003120_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  background-attachment: fixed;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  font-size: 24px;
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;
  padding: $spacing-unit;
  box-shadow: 4px 5px 10px #777;
  background: #fefefe;
.pagination .active {
  background: red;
}
  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}
